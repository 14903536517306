import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/10C/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/10C/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/10C/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/10C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/12A/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/12A/6.jpg';

// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly10C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/10C/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/10C/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/10C/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/10C/4.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },

    //   {
    //     src: p7,
    //     source: p7,
    //     rows: 1,
    //     cols: 2,
    //   },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      'Yoga for Mental Nutrition'
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 10C Date: 7 JULY 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Class 10 C had conducted their assembly on the topic, ‘Yoga for Mental Nutrition” on Friday, 7 July 2023.
         The students learnt that yoga can reduce stress, anxiety and depression which in turn enhances one’s overall 
         mental clarity and resilience. The students evidently showcased  the importance of practicing Yoga for mental 
         nutrition which was a powerful means to prioritise one’s mental health to achieve a state of holistic well-being 
         with an innovative skit and a power-packed dance recital.

 {/* <br></br>
 The students not only learnt something completely new in the form of Indian Sign Language, but also effectively 
 communicated the significance of inclusivity and the necessary measures to be taken to an attentive audience.  */}
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Yoga does not just change the way we see things, it transforms the person who sees”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10C2023;